import React, { useEffect, useState } from "react";
import { Helmet as ReactHelmet } from "react-helmet";
import PropTypes from "prop-types";
import moment from "moment";

const DOMAIN = "https://elmuseoviajero.com.ar";
const DEFAULT_IMAGE_CARD = `${DOMAIN}/OGimage_1200x630.jpg`;
const DEFAULT_TITLE = "El Museo Viajero - Comédias Históricas";
const DEFAULT_DESCRIPTION = "Comédias Históricas";
const FAVICON_SOURCE = `${DOMAIN}/favicon.ico`;

const HelmetCartelera = ({
  title = DEFAULT_TITLE,
  description = DEFAULT_DESCRIPTION,
  link = "/",
  keywords = "",
  imageCard = DEFAULT_IMAGE_CARD,
  sendStructuredData = false,
  scheduleList = [],
}) => {
  const [metaLink, setMetaLink] = useState(DOMAIN);

  useEffect(() => {
    setMetaLink(DOMAIN + link);
  }, [link]);

  const formatTime = (timeString) => {
    const [hour, minute] = timeString.split(":");
    return `${hour.padStart(2, "0")}:${minute ? minute.trim() : "00"}`;
  };

  console.log(scheduleList)

  const determinarTemporada = (scheduleList) => {
    // Obtener todas las fechas de las funciones
    const fechas = scheduleList.flatMap((obra) =>
      obra.funciones.map((funcion) => moment(funcion.funcionFecha, "YYYY-MM-DD"))
    );
  
    // Ordenar las fechas
    fechas.sort((a, b) => a - b);
  
    // Obtener la primera y la última fecha
    const inicioDeTemporada = fechas[0];
    const finDeTemporada = fechas[fechas.length - 1];
  
    return {
      inicioDeTemporada: inicioDeTemporada,
      finDeTemporada: finDeTemporada,
    };
  };

  const { inicioDeTemporada, finDeTemporada } = determinarTemporada(scheduleList);

  console.log(inicioDeTemporada, finDeTemporada);

  const currentYear = new Date().getFullYear();

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "TheaterEvent",
    name: title,
    description: description,
    image: imageCard,
    organizer: {
      "@type": "Organization",
      name: "El Museo Viajero",
      url: DOMAIN,
    },
    location: {
      "@type": "Place",
      name: "Salas del Museo Viajero o en las escuelas",
      address: {
        "@type": "PostalAddress",
        addressLocality: "CABA",
        addressRegion: "Buenos Aires",
      },
    },
    startDate: `${currentYear}-04-01`,
    endDate: `${currentYear}-12-20`,
    performer: {
      "@type": "PerformingGroup",
      name: "El Museo Viajero",
    },
    workPerformed: {
      "@type": "CreativeWork",
      name: title,
      description: description,
    },
    subEvent: scheduleList.flatMap((obra) =>
      obra.funciones.map((funcion) => {
        const startDate = moment(`${funcion.funcionFecha}T${formatTime(funcion.funcionHora)}:00-03`).format("YYYY-MM-DDTHH:mm:ssZ");
        const endDate = moment(startDate).add(1, 'hours').format("YYYY-MM-DDTHH:mm:ssZ");
        // console.log("startDate:", startDate);
        // console.log("endDate:", endDate);
        return {
          "@type": "Event",
          name: obra.obra, // Agregar el nombre de la obra
          startDate: startDate,
          endDate: endDate,
          location: {
            "@type": "Place",
            name: obra.sala.nombreSala,
            address: {
              "@type": "PostalAddress",
              streetAddress: obra.sala.direccionSala,
              addressLocality: "CABA",
              addressRegion: "Buenos Aires",
            },
          },
          offers: {
            "@type": "Offer",
            price: funcion.precioFuncion,
            priceCurrency: "ARS",
          },
        };
      })
    ),
  };

  // Log the structured data to the console
  // console.log("Structured Data:", JSON.stringify(structuredData, null, 2));

  return (
    <ReactHelmet>
      <html lang='es' />
      <title>{title}</title>
      <meta name='description' content={description} />
      <link rel='canonical' href={metaLink} />
      <meta name='keywords' content={keywords} />
      <link rel='icon' href={FAVICON_SOURCE} />
      <meta property='og:url' content={metaLink} />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta property='og:image' content={imageCard} />
      <meta property='og:image:width' content='1200' />
      <meta property='og:image:height' content='630' />
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={description} />
      <meta name='twitter:image' content={imageCard} />
      {sendStructuredData && (
        <script type='application/ld+json'>
          {JSON.stringify(structuredData)}
        </script>
      )}
    </ReactHelmet>
  );
};

HelmetCartelera.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  keywords: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  imageCard: PropTypes.string,
  sendStructuredData: PropTypes.bool,
  scheduleList: PropTypes.array,
};

export default HelmetCartelera;