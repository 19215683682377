import { useContext } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import moment from "moment/moment";

import { ContenidoCartelra } from "../ContenidoCartelera/ContenidoCartelra";
import { ObrasPorMes } from "../ObrasPorMes/ObrasPorMes";
import { paths } from "../../data/dataSourese";
import { useNavigate } from "react-router-dom";
import { DataContex } from "../../context/DataContext";

import styles from "./Pages.module.scss";
import { ScrollPercentage } from "../ScrollPercentage/ScrollPercentage";
import HelmetCartelera from "./HelmetCartelera";

export const CarteleraPage = () => {
  const navigate = useNavigate();

  const { arrayAgrupado: carteleraData, salas } = useContext(DataContex);

  const handleClick = (obraElegida) => {
    navigate(`/${paths.obras}`);
  };

  const consolidarObrasEnCartel = (carteleraData, salas) => {
    return carteleraData.map((obra) => {
      const salaInfo = salas.find((sala) => sala.nombreSala === obra.sala);
  
      return {
        obra: obra.title, // Cambiar de obra.ID a obra.nombre
        sala: {
          nombreSala: obra.sala,
          direccionSala: salaInfo ? salaInfo.direccion : "Dirección no encontrada"
        },
        funciones: obra.funciones.flatMap((funcion) => {
          // Separar las horas usando "," y "y"
          const horas = funcion.hora.split(/[ ,y]+/).map((h) => h.trim().replace(/[^0-9.:]/g, "")).filter(h => h && h !== ".");
          
          // console.log("Horas separadas:", horasEnd); // Agregar console.log para verificar las horas separadas

          return horas.map((h) => ({
            funcionFecha: moment(funcion.fechaFuncion, "D [de] MMMM [de] YYYY").format("YYYY-MM-DD"),
            funcionHora: moment(h, ["H", "H.mm", "H:mm"]).format("HH:mm"),
    
            precioFuncion: parseFloat(funcion.precio.replace(".", ""))
          }));
        })
      };
    });
  };

  // Consolidar los datos de las obras en cartel
  const obrasEnCartel = consolidarObrasEnCartel(carteleraData, salas);
  // console.log("Obras en Cartel:", JSON.stringify(obrasEnCartel, null, 2));
  // console.log("Cartelera Data:", carteleraData);



  return (
    <>
      <HelmetCartelera
        title={`Obras en cartel | Teatro histórico para escuelas en CABA`}
        description={"Consultá las funciones programadas en el Museo Viajero. Obras teatrales para escuelas y público que recrean la historia argentina. Funciones en CABA y más. ¡Consultá horarios y precio de las entradas!"}
        link={`/${paths.cartelera}`}
        sendStructuredData={true}
        scheduleList={obrasEnCartel}
      />

      <Row className={`${styles.page}`}>
        <Container className='mt-5  mb-1'>
          <Container>
            <h1>Obras en cartel en el Museo Viajero</h1>
            <p>
            Funciones especiales para escuelas y público que recrean la historia a través del teatro <br/>
            Presentaciones en teatros, museos y escuelas<br /> 
            Entradas anticipadas con descuentos los fines de semana
            </p>
          </Container>
        </Container>
        <ContenidoCartelra />
        <Container fluid>
          <Container>
            <Row className={`justify-content-center mt-5`}>
              <Col md={4} className='d-grid' onClick={handleClick}>
                <Button size='lg'>Ver todas nuestras obras</Button>
              </Col>
            </Row>
          </Container>
        </Container>
        <ObrasPorMes mesesAMostrar={9} />
        <ScrollPercentage maxScroll={20} />
      </Row>
    </>
  );
};