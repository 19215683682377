import { Col, Container, Image, Row } from "react-bootstrap";
import { funcionAgotada } from "../../assets";
import WhatsAppButton from "../WhatsAppButton/WhatsAppButton";

import sharedStyles from "../../scss/sharedStyles.module.scss";
import styles from "./ObrasPorMes.module.scss";
import { MapButton } from "../MapButton/MapButton";
import { useContext, useEffect, useState } from "react";
import { DataContex } from "../../context/DataContext";
import moment from "moment";



export const FuncionRow = ({ data, id, item }) => {
  moment.locale("es"); // Establece el locale en español

  const { salas, windowSize } = useContext(DataContex);
  const [salaInfo, setSalaInfo] = useState({});
  const [enPromo, setEnPromo] = useState('');

  const obtenerNombreDelDia = (fechaString) => {
    const formato = "D [de] MMMM [de] YYYY"; // Asegúrate de que el formato coincida con tu cadena de fecha
    const fecha = moment(fechaString, formato);
    return fecha.format("dddd"); // Devuelve el nombre del día
  };



  useEffect(() => {
    const obtenerSalaPorNombre = (nombre) =>
      salas.find(
        (sala) => sala.nombreSala.toLowerCase() === nombre.toLowerCase()
      );

    setSalaInfo(obtenerSalaPorNombre(data?.sala) || "");
  }, [data.sala, salas]);

  const [isXs, setIsXs] = useState(false);

  useEffect(() => {
    if (windowSize.windowsName === "appXs") {
      setIsXs(true);
    }
  }, [windowSize.windowsName]);

  useEffect(() => {
    if (data.promoName !== "") {
      setEnPromo(data.promoName);
    }
  }, [data.promoName]);

  const formatHorarios = (horarios) => {
    const horariosArray = horarios.split(", ");
    if (horariosArray.length === 1) {
      return horarios;
    }
    const lastHorario = horariosArray.pop();
    return `${horariosArray.join(", ")} y ${lastHorario}`;
  };
  

  return (
    <>
      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
      <Row key={id} className={` ${sharedStyles.float}`}>
        <Container
          fluid
          className={`${styles.funcion} ${
            data.promoName !== "" && `${styles.promo}`
          } row`}>
          {data.promoName !== "" && (
            <Col xs={12} className={`${styles.promoName}`}>
              <strong>{data.promoName}</strong>
            </Col>
          )}
          <Col md={8} style={{ position: "relative" }}>
            <h4>
              <small>{obtenerNombreDelDia(data.fechaFuncion)}</small>
              <br />
              <strong>{`${data.fechaFuncion}`}</strong>
            </h4>
            <li>
              <span>Horarios: </span> {isXs ? <br /> : ""}{" "}
              <strong>{`${formatHorarios(data.hora)} hs.`}</strong>
            </li>
            <li>
              <span>Precio: </span> {isXs ? <br /> : ""}{" "}
              <strong>{`$ ${data.precio}`}</strong>
            </li>
            <li>
              <span>Sala: </span> {isXs ? <br /> : ""}{" "}
              <strong>{`${salaInfo.nombreSala}`}</strong>
            </li>
            <li>
              <span>Dirección: </span> {isXs ? <br /> : ""}{" "}
              <strong>{`${salaInfo.direccion}`}</strong>
            </li>
            {data.agotada === "si" ? (
              <Col className={styles.funcionAgotada}>
                <Image src={funcionAgotada} />
              </Col>
            ) : (
              ""
            )}
          </Col>
          <Col xs={12} lg={4}>
            <Row className='gap-2'>
              <Col className={`h-100 d-flex  `}>
                <WhatsAppButton
                  fechaFuncion={data.fechaFuncion}
                  titleFuncion={item.title}
                  sala={salaInfo.nombreSala}
                  text='Comprar entradas'
                  className='ReservarFuncionTeatro'
                  enPromo={enPromo}
                  id={`${item.title}_${data.fechaFuncion}_${salaInfo.nombreSala}`}
                />
              </Col>
              <Col className={`h-100 d-flex  `}>
                <MapButton text='Ver ubicación' data={data} />
              </Col>
            </Row>
          </Col>
          {data.promoName !== "" && (
            <Col xs={12} className={`${styles.promoDescription}`}>
              {data.promoDescription}
            </Col>
          )}
        </Container>
      </Row>
    </>
  );
};
