import { createContext, useState, useEffect } from "react";
import Papa from "papaparse";
import { dataSource, paths, navBarNavigation } from "../data/dataSourese";
import moment from "moment/moment";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebaseConfig/firebase";

export const DataContex = createContext();

export const customBreackpoint = [
  { appXs: 0 },
  { appSm: 576 },
  { appMd: 768 },
  { appLg: 992 },
  { appXl: 1200 },
  { appXxl: 1400 },
  { appFull: 1920 },
  { app4k: 2560 },
];

export function DataContextProvider({ children }) {
  const [isLoading, setIsLoading] = useState(true);

  const [windowSize, setWindowSize] = useState({});

  const handleResize = () => {
    const windowMatch = customBreackpoint.find((windowSize, index) => {
      const nextWindowSize = customBreackpoint[index + 1];

      if (nextWindowSize) {
        const currentKey = Object.keys(windowSize)[0];
        const currentWidth = windowSize[currentKey];
        const nextKey = Object.keys(nextWindowSize)[0];
        const nextWidth = nextWindowSize[nextKey];
        const minWidth = `(min-width: ${currentWidth}px)`;
        const maxWidth = `(max-width: ${nextWidth - 0.02}px)`;

        if (window.matchMedia(`${minWidth} and ${maxWidth}`).matches) {
          return windowSize;
        }
      } else {
        const key = Object.keys(windowSize)[0];
        const value = windowSize[key];
        if (window.matchMedia(`(min-width: ${value}px)`).matches) {
          return windowSize;
        }
      }

      return null;
    });

    if (windowMatch) {
      const key = Object.keys(windowMatch)[0];
      setWindowSize({
        windowsName: key,
        pixelWidth: windowMatch[key],
      });
    }
  };

  useEffect(() => {
    handleResize(); // Set initial window size
    window.addEventListener("resize", handleResize); // Event listener for window resize
    return () => {
      window.removeEventListener("resize", handleResize); // Clean up on unmount
    };
  }, []);

  // Dasta set
  const sheetID = process.env.REACT_APP_SHEET_ID;

  const [obrasEnCartelera, setObrasEnCartelera] = useState([]);

  const [obrasActivas, setObrasActivas] = useState([]);

  const [carteleraData, setCarteleraData] = useState([]);

  const [obrasDataMerged, setObrasDataMerged] = useState([]);

  const [obrasRecursos, setObrasRecursos] = useState([]);

  const [temasObras, setTemasObras] = useState([]);

  const [videosObras, setVideosObras] = useState([]);

  const [heroSlider, setHeroSlider] = useState([]);

  const [salas, setSalas] = useState([]);

  const [userIsLogin, setUserIsLogin] = useState(false);

  const [obrasId, setObrasId] = useState([]);

  const [categoriasRecursosSource, setCategoriasRecursosSource] = useState([]);

  const [prensaData, setPrensaData] = useState([]);

  const [calculatorData, setCalculatorData] = useState([]);

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // El usuario está autenticado
        setUserIsLogin(true);
      } else {
        // No hay usuario autenticado
        setUserIsLogin(false);
      }
    });

    // Limpia el listener al desmontar el componente
    return () => unsubscribe();
  }, []);

  const cartelera = "393736360";
  const recursos = "1257531079";
  const temas = "1888943415";
  const videos = "1389993981";
  const slider = "394605623";
  const salasDB = "129995417";
  const obras = "0";
  const categoriasRecursos = "2079661031";
  const prensa = "210931182";
  const calculator = "954763227";

  const tipoDeMaterial = ["Actividad", "Investigación", "Crucigrama"];

  const makeCartelera = async () => {
    try {
      setIsLoading(true);

      const results = await new Promise((resolve, reject) => {
        Papa.parse(
          `https://docs.google.com/spreadsheets/d/${sheetID}/export?gid=${cartelera}&format=csv`,
          {
            download: true,
            header: true,
            complete: (results) => resolve(results),
            error: (error) => reject(error),
          }
        );
      });

      // Procesa los resultados y actualiza el estado
      setObrasEnCartelera(results.data);
    } catch (error) {
      // Maneja cualquier error que ocurra durante la obtención o procesamiento de los datos
      console.error("Error al procesar cartelera:", error);
    } finally {
      // Establece isLoading en false independientemente de si ocurrió un error o no
      setIsLoading(false);
    }
  };

  const makeSalas = async () => {
    try {
      setIsLoading(true);
      const results = await new Promise((resolve, reject) => {
        Papa.parse(
          `https://docs.google.com/spreadsheets/d/${sheetID}/export?gid=${salasDB}&format=csv`,
          {
            download: true,
            header: true,
            complete: (results) => resolve(results),
            error: (error) => reject(error),
          }
        );
      });

      setSalas(results.data);
    } catch (error) {
      console.error("Error al procesar Salas", error);
    } finally {
      setIsLoading(false);
    }
  };

  const makeTemas = async () => {
    try {
      setIsLoading(true);
      const results = await new Promise((resolve, reject) => {
        Papa.parse(
          `https://docs.google.com/spreadsheets/d/${sheetID}/export?gid=${temas}&format=csv`,
          {
            download: true,
            header: true,
            complete: (results) => resolve(results),
            error: (error) => reject(error),
          }
        );
      });
      setTemasObras(results.data);
    } catch (error) {
      console.error("Error al procesar Temas", error);
    } finally {
      setIsLoading(false);
    }
  };

  const makeObrasId = async () => {
    try {
      setIsLoading(true);
      const results = await new Promise((resolve, reject) => {
        Papa.parse(
          `https://docs.google.com/spreadsheets/d/${sheetID}/export?gid=${obras}&format=csv`,
          {
            download: true,
            header: true,
            complete: (results) => resolve(results),
            error: (error) => reject(error),
          }
        );
      });
      setObrasId(results.data);
    } catch (error) {
      console.error("Error al procesar Obras ID", error);
    } finally {
      setIsLoading(false);
    }
  };

  const makeCategoriasRecursosSource = async () => {
    try {
      setIsLoading(true);
      const results = await new Promise((resolve, reject) => {
        Papa.parse(
          `https://docs.google.com/spreadsheets/d/${sheetID}/export?gid=${categoriasRecursos}&format=csv`,
          {
            download: true,
            header: true,
            complete: (results) => resolve(results),
            error: (error) => reject(error),
          }
        );
      });
      setCategoriasRecursosSource(results.data);
    } catch (error) {
      console.error("Error al procesar Catergorias de los Recursos", error);
    } finally {
      setIsLoading(false);
    }
  };

  const makeVideosObras = async () => {
    try {
      setIsLoading(true);
      const results = await new Promise((resolve, reject) => {
        Papa.parse(
          `https://docs.google.com/spreadsheets/d/${sheetID}/export?gid=${videos}&format=csv`,
          {
            download: true,
            header: true,
            complete: (results) => resolve(results),
            error: (error) => reject(error),
          }
        );
      });
      setVideosObras(results.data);
    } catch (error) {
      console.error("Error al procesar Los videos de las obras", error);
    } finally {
      setIsLoading(false);
    }
  };

  const makeRecursos = async () => {
    try {
      const results = await new Promise((resolve, reject) => {
        Papa.parse(
          `https://docs.google.com/spreadsheets/d/${sheetID}/export?gid=${recursos}&format=csv`,
          {
            download: true,
            header: true,
            complete: (results) => resolve(results),
            error: (error) => reject(error),
          }
        );
      });

      // Procesa los resultados y actualiza el estado
      const obrasRecursosConBooleanos = results.data.map((item) => ({
        ...item,
        inicial: item.inicial === "TRUE",
        primerCiclo: item.primerCiclo === "TRUE",
        segundoCiclo: item.segundoCiclo === "TRUE",
        tercerCiclo: item.tercerCiclo === "TRUE",
        secundario: item.secundario === "TRUE",
      }));
      setObrasRecursos(obrasRecursosConBooleanos);
    } catch (error) {
      // Maneja cualquier error que ocurra durante la obtención o procesamiento de los datos
      console.error("Error al procesar recursos:", error);
    }
  };

  const makeHeroSlider = async () => {
    try {
      setIsLoading(true);

      const results = await new Promise((resolve, reject) => {
        Papa.parse(
          `https://docs.google.com/spreadsheets/d/${sheetID}/export?gid=${slider}&format=csv`,
          {
            download: true,
            header: true,
            complete: (results) => resolve(results),
            error: (error) => reject(error),
          }
        );
      });

      // Procesa los resultados y actualiza el estado
      const slidsList = results.data.map((item) => ({
        ...item,
        estreno: item.estreno === "TRUE",
      }));
      setHeroSlider(slidsList);
    } catch (error) {
      // Maneja cualquier error que ocurra durante la obtención o procesamiento de los datos
      console.error("Error al procesar Hero Slider:", error);
    } finally {
      // Establece isLoading en false independientemente de si ocurrió un error o no
      setIsLoading(false);
    }
  };

  const makePrensaData = async () => {
    try {
      setIsLoading(true);

      const results = await new Promise((resolve, reject) => {
        Papa.parse(
          `https://docs.google.com/spreadsheets/d/${sheetID}/export?gid=${prensa}&format=csv`,
          {
            download: true,
            header: true,
            complete: (results) => resolve(results),
            error: (error) => reject(error),
          }
        );
      });

      const notasDePrensa = results.data.map((item) => ({
        ...item,
        slider: item.slider === "TRUE",
      }));
      setPrensaData(notasDePrensa);
    } catch (error) {
      // Maneja cualquier error que ocurra durante la obtención o procesamiento de los datos
      console.error("Error al procesar Notas de Prensa:", error);
    } finally {
      // Establece isLoading en false independientemente de si ocurrió un error o no
      setIsLoading(false);
    }
  };

  const makeCalculatorData = async () => {
    try {
      setIsLoading(true);

      const results = await new Promise((resolve, reject) => {
        Papa.parse(
          `https://docs.google.com/spreadsheets/d/${sheetID}/export?gid=${calculator}&format=csv`,
          {
            download: true,
            header: true,
            complete: (results) => resolve(results),
            error: (error) => reject(error),
          }
        );
      });

      const calculatorData = results.data.map((item) => {
        const cleanedValorDeLaFuncion = item.valorDeLaFuncion
          .replace(/\./g, "") // Remove periods
          .replace(/\$/g, "") // Remove dollar sign
          .split(",")[0]; // Remove decimal values after the comma

        const porcentajeValue =
          parseFloat(item.porcentaje.replace(",", ".").replace("%", "")) / 100;

        return {
          ...item,
          publicoMinPorFuncion: parseInt(item.publicoMinPorFuncion, 10),
          publicoMaxPorFuncion: parseInt(item.publicoMaxPorFuncion, 10),
          valorDeLaFuncion: parseInt(cleanedValorDeLaFuncion, 10),
          totalDeFunciones: parseInt(item.totalDeFunciones, 10),
          promoActiva: item.promoActiva === "si",
          porcentajeValue: porcentajeValue,
        };
      });

      setCalculatorData(calculatorData);

      // console.log(calculatorData);
    } catch (error) {
      // Maneja cualquier error que ocurra durante la obtención o procesamiento de los datos
      console.error("Error al procesar Datos del Calculador:", error);
    } finally {
      // Establece isLoading en false independientemente de si ocurrió un error o no
      setIsLoading(false);
    }
  };

  const recursosCollection = collection(db, "recursos");

  const [recursosFirebase, setRecursosFirebase] = useState([]);

  const getRecursosFirebase = async () => {
    const data = await getDocs(recursosCollection);

    setRecursosFirebase(
      data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
    );
  };

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true); // Asume que tienes un estado llamado isLoading
      try {
        await Promise.all([
          makeHeroSlider(),
          makeCartelera(),
          makeRecursos(),
          makeTemas(),
          makeVideosObras(),
          makeSalas(),
          makeObrasId(),
          getRecursosFirebase(),
          makeCategoriasRecursosSource(),
          makePrensaData(),
          makeCalculatorData(),
        ]);
      } catch (error) {
        console.error("Error cargando datos", error);
        // Actualiza el estado con el error si tienes un estado para eso
      }
      setIsLoading(false);
    };

    loadData();
    // Al usar una función definida dentro de useEffect, no necesitas añadir nada a las dependencias.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Este efecto se ejecuta solo una vez, cuando el componente se monta

  // dataSource js
  const convertirFechas = (data) => {
    data.forEach((item) => {
      item.fechaInicio = moment(item.fechaInicio, "D/MM/YY").toDate();
      item.fechaFin = moment(item.fechaFin, "D/MM/YY").toDate();
    });
  };
  convertirFechas(obrasEnCartelera);

  const filtrarPorFechaActual = (data) => {
    setIsLoading(true);
    const fechaActual = moment(); // Obtiene la fecha y hora actual
    return data.filter((item) => {
      const fechaInicio = moment(item.fechaInicio);
      const fechaFin = moment(item.fechaFin);
      const siempreVisible = item.siempreVisible;
      setIsLoading(false);
      return (
        fechaActual
          .startOf("day")
          .isBetween(
            fechaInicio.startOf("day"),
            fechaFin.startOf("day"),
            null,
            "[]"
          ) || siempreVisible === "si"
      ); // Inclusivo en ambos extremos
    });
  };

  // Llama a la función para filtrar los elementos
  const combinarArrays = (array1, array2) => {
    setIsLoading(true);

    return array1.map((elemento1) => {
      const elemento2 = array2.find((e) => e.ID === elemento1.ID);
      if (elemento2) {
        setIsLoading(false);
        return { ...elemento1, ...elemento2 };
      }
      setIsLoading(false);
      return elemento1;
    });
  };

  useEffect(() => {
    setIsLoading(true);
    setObrasActivas(filtrarPorFechaActual(obrasEnCartelera));
    setIsLoading(false);
  }, [obrasEnCartelera]);

  useEffect(() => {
    setCarteleraData(combinarArrays(obrasActivas, dataSource));
    setObrasDataMerged(combinarArrays(obrasEnCartelera, dataSource));
  }, [obrasActivas, obrasEnCartelera]);

  // console.log(carteleraData);
  // console.log(obrasId);

  function buscarEstreno(ID) {
    const obra = obrasId.find((obra) => obra.ID === ID);
    return obra ? obra.estreno : "no"; // Retorna 'no' si no se encuentra el ID
  }

  function buscarEstrenoText(ID) {
    const obra = obrasId.find((obraText) => obraText.ID === ID);
    return obra ? obra.estrenoText : obrasId.estrenoText;
  }

  const arrayAgrupado = carteleraData.reduce((acumulado, actual) => {
    const { ID, fechaFuncion, hora, precio, direccion, ...resto } = actual;

    const encontrado = acumulado.find((elemento) => elemento.ID === ID);

    if (encontrado) {
      encontrado.funciones.push({ fechaFuncion, hora, precio, direccion });
    } else {
      acumulado.push({
        ID,
        estreno: buscarEstreno(ID), // Agrega el estreno aquí usando la función de búsqueda
        funciones: [{ fechaFuncion, hora, precio, direccion }],
        estrenoText: buscarEstrenoText(ID),
        ...resto,
      });
    }

    return acumulado;
  }, []);

  const [recursosMapeadosFire, setRecursosMapeadosFire] = useState([]);

  // Mapeo para ajustar la estructura del array de origen
  const ajustarArrayOrigen = (arrayOrigen) =>
    arrayOrigen.map((item) => ({
      descargas: item.descargas,
      description: item.description,
      googleId: "", // Debes establecer el valor correcto si lo tienes disponible
      inicial: item.niveles.includes("inicial"),
      link: item.link,
      obraID: item.obraID,
      primerCiclo: item.niveles.includes("primerCiclo"),
      secundario: item.niveles.includes("secundario"),
      segundoCiclo: item.niveles.includes("segundoCiclo"),
      tercerCiclo: item.niveles.includes("tercerCiclo"),
      tipo: item.tipo,
      title: item.title,
      id: item.id,
    }));

  useEffect(() => {
    const data = ajustarArrayOrigen(recursosFirebase);
    setRecursosMapeadosFire(data);
  }, [recursosFirebase]);

  const datos = {
    isLoading,
    dataSource,
    obrasDataMerged,
    obrasRecursos,
    arrayAgrupado,
    paths,
    navBarNavigation,
    temasObras,
    videosObras,
    heroSlider,
    salas,
    userIsLogin,
    obrasId,
    tipoDeMaterial,
    recursosMapeadosFire,
    categoriasRecursosSource,
    windowSize,
    prensaData,
    calculatorData,
  };

  return <DataContex.Provider value={datos}>{children}</DataContex.Provider>;
}
