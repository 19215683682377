import { Container } from "react-bootstrap";
import { Recursos } from "../Recusrsos/Recursos";
import { BannerTopPages } from "../BannerTopPages/BannerTopPages";
import { materiales } from "../../assets";
import { useContext, useEffect, useState } from "react";
import { DataContex } from "../../context/DataContext";
import { ScrollPercentage } from "../ScrollPercentage/ScrollPercentage";
import Helmet from "../Helmet";
import { paths } from "../../data/dataSourese";

export const MaterialesPage = () => {
  const { windowSize } = useContext(DataContex);

  const [isXs, setIsXs] = useState(false);

  useEffect(() => {
    if (windowSize.windowsName === "appXs") {
      setIsXs(true);
    }
  }, [windowSize.windowsName]);

  return (
    <>
      <Helmet
        title={`Material didáctico`}
        link={`/${paths.materiales}`}
        location={{}}
        description={`En este apartado hay investigaciones, actividades y crucigramas para descargar.
                      Están agrupados según las siguientes categorías en orden alfabético:
                      • Cristóbal Colón • Domingo F. Sarmiento • Historia de San Isidro • Independencia • Invasiones inglesas • José de San Martín • Manuel Belgrano • Pueblos originarios • Revolución de Mayo • Soberanía • Tradición • Vestimenta • Vida cotidiana, costumbres y comidas`}
      />
      <Container fluid>
        {isXs ? (
          <BannerTopPages
            img={materiales}
            positionY={"30%"}
            margBottom={"-65%"}
          />
        ) : (
          <BannerTopPages
            img={materiales}
            positionY={"30%"}
            margBottom={"-18%"}
          />
        )}
        <Container>
          <Recursos />
        </Container>
        <ScrollPercentage maxScroll={20} />
      </Container>
    </>
  );
};
