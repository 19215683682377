import React, { useContext, useEffect, useState } from "react";
import { Accordion, Button, Col, Container, Image, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { LottieConatiner } from "../LottieContainer/LottieConatiner";
import DOMPurify from "dompurify";
import { DataContex } from "../../context/DataContext";
import { PhotoGalery } from "../PhotoGalery/PhotoGalery";
import { ProximasFunciones } from "../ProximasFunciones/ProximasFunciones";
import { MaterialDownloader } from "../MaterialDownloader/MaterialDownloader";
import { ObraCard } from "./ObraCard";
import { paths, publicaciones } from "../../data/dataSourese";
import { VideoLoader } from "../VideoLoader/VideoLoader";
import { award, calculadorCta } from "../../assets";
import WhatsAppButtonReserva from "../WhatsAppButton/WhatsAppButtonReservar";

import styles from "./ObrasInfo.module.scss";
import sharedStyles from "../../scss/sharedStyles.module.scss";
import Helmet from "../Helmet";
import { ScrollPercentage } from "../ScrollPercentage/ScrollPercentage";


export const ObraInfo = ({ obra }) => {
  const sanitizer = DOMPurify.sanitize;

  const [tienePublicaciones, setTienePublicaciones] = useState(false);

  const {
    salas,
    obrasDataMerged,
    dataSource,
    videosObras,
    recursosMapeadosFire: obrasRecursos,
    windowSize,
  } = useContext(DataContex);

  const [obraDisplayID, setObraDisplayID] = useState("");
  const [materiales, setMateriales] = useState(false);
  const navigate = useNavigate();
  const handleClick = (obraElegida) => {
    navigate(`/${paths.obras}/${obraElegida}`);
  };

  function tieneMateriales(obras) {
    return obras.obraID === obra.ID;
  }

  const otrasObrasDelTema = (tema) => {
    return dataSource.filter((obra) => {
      return obra.temaObra === tema.temaObra && obra.title !== tema.title;
    });
  };

  const videosDeLaObra = (tema) => {
    return videosObras.filter((obra) => {
      return obra.ID === tema.ID;
    });
  };

  useEffect(() => {
    setObraDisplayID(obra.ID);

    if (obrasRecursos.find(tieneMateriales) === undefined) {
      setMateriales(false);
    } else {
      setMateriales(true);
    }

    buscarStringEnObjeto(publicaciones, obra.ID);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materiales, obra.ID, obrasRecursos]);

  const [isXs, setIsXs] = useState(false);

  useEffect(() => {
    if (windowSize.windowsName === "appXs") {
      setIsXs(true);
    }
  }, [obra.ID, windowSize.windowsName]);

  const buscarStringEnObjeto = (objeto, busqueda) => {
    // Buscar la cadena en el objeto
    const objetoEncontrado = Object.keys(objeto).find((clave) =>
      objeto[clave].includes(busqueda)
    );

    // Verificar si la cadena fue encontrada
    if (objetoEncontrado) {
      // console.log(`La cadena "${busqueda}" se encuentra en el objeto: ${objetoEncontrado}`);

      setTienePublicaciones(true);
    } else {
      // console.log(`La cadena "${busqueda}" no está en ninguno de los objetos.`);
      setTienePublicaciones(false);
    }
  };

  // TODO: crear schedule de las presentaciones

  const getScheduleById = (obrasDataMerged, id) => {
    return obrasDataMerged
      .filter((obra) => obra.ID === id)
      .map((obra) => ({
        "@type": "Schedule",
        startDate: obra.fechaFin,
        endDate: obra.fechaFin,
        location: {
          "@type": "Place",
          name: obra.sala,
        },
        startTime: obra.hora.split(",")[0], // Tomamos la primera hora del array
        endTime: obra.hora.split(",").slice(-1)[0], // Tomamos la última hora del array
        // También podrías considerar el timezone
      }));
  };


  return (
    <>
      <Helmet
        title={obra.title}
        link={`/${obra.ID}`}
        description={`${obra.body.trim()}`}
        imageCard={`${obra.imgPortada}`}
        awards={obra.premios.map((premio) => premio.premio)}
        temas={obra.temas}
        sendStructuredData={true}
        type='TheaterEvent'
        canonicalUrl={`/obras/${obra.ID}`}
        startDate={obra.startDate}
        location={{ addressLocality: "CABA", addressRegion: "Buenos Aires" }}
        scheduleList={getScheduleById(obrasDataMerged, obra.ID)}
        salas={salas}
        keywords={obra.keywords}
      />
      <Container fluid className={`${styles.obraHeader} pb-2`}>
        <Container>
          <Col xs={12} className={`mb-4`}>
            <h1>{obra.title}</h1>
            <h2>{obra.subTitle}</h2>
          </Col>
        </Container>
      </Container>
      <Container fluid>
        <Container>
          <Row className={`mb-5 mt-1 g-5`}>
            <Col md={6} className={styles.obraInfo}>
              {/* Mobile  */}
              {isXs && (
                <Col style={{ position: "relative" }} className={`mb-4`}>
                  <Row
                    style={{
                      position: "absolute",
                      width: "100%",
                      left: "0.5em",
                      height: "25%",
                      bottom: "5%",
                    }}
                    className='align-items-end justify-content-end '>
                    <Col md={4}>
                      <WhatsAppButtonReserva
                        text='Reservar función'
                        titleFuncion={obra.title}
                        className={"ReservarFuncionViajera"}
                        id={obra.title}
                      />
                    </Col>
                    <Col md={1} className='calculadorCTA'>
                      <Col className={`${styles.calculadorCTA}`}>
                        <Link to='/calculador' className='calculadorCTA'>
                          <LottieConatiner
                            lottieJason={calculadorCta}
                            viewerHeigth='100%'
                            classSendet='calculadorCTA'
                          />
                        </Link>
                      </Col>
                    </Col>
                  </Row>
                  <Image src={obra.imgPortada} fluid rounded />
                </Col>
              )}
              {obra.tipoDeObra && (
                <h4 className={styles.tipoDeObra}>{obra.tipoDeObra}</h4>
              )}
              {obra.body && (
                <p dangerouslySetInnerHTML={{ __html: sanitizer(obra.body) }} />
              )}
              {obra.temas.length >= 1 && (
                <ul className={`${styles.temas}`}>
                  {obra.temas.map((data, id) => (
                    <li
                      key={id}
                      dangerouslySetInnerHTML={{ __html: sanitizer(data.tema) }}
                    />
                  ))}
                </ul>
              )}
              {tienePublicaciones && (
                <Container className='mb-4'>
                  <Link to={`/${paths.publicaciones}#${obra.ID}`}>
                    <Row>
                      <Button>Ver libro</Button>
                    </Row>
                  </Link>
                </Container>
              )}
              {obra.premios.length > 1 && <h4>Premios y menciones</h4>}
              <ul className={`${styles.premios}`}>
                {obra.premios.map((data, id) => (
                  <li key={id}>
                    <Row>
                      <Col xs={1} md={2} style={{ padding: "0px" }}>
                        <LottieConatiner
                          lottieJason={award}
                          viewerHeigth='3em'
                          animSpeed={30}
                        />
                      </Col>
                      <Col>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: sanitizer(data.premio),
                          }}
                        />
                      </Col>
                    </Row>
                  </li>
                ))}
              </ul>
              {obra.pie && (
                <Col className={`${styles.obraPie}`}>
                  <Col>
                    <p>{obra.pie}</p>
                  </Col>
                </Col>
              )}
              {materiales && (
                <Accordion className={`mt-5`} flush>
                  <Accordion.Item eventKey='0'>
                    <Accordion.Header className={`${styles.tituloAcorion}`}>
                      <span>Material didáctico:</span>
                    </Accordion.Header>
                    <Accordion.Body>
                      {obrasRecursos.map((data, id) => (
                        <div key={id}>
                          {data.obraID === obraDisplayID && (
                            <MaterialDownloader data={data} />
                          )}
                        </div>
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              )}
              <Col className={`${styles.obraEnLaEscuela}`}>
                <Row className={`p-2 my-4  ${sharedStyles.float}`}>
                  <WhatsAppButtonReserva
                    text='Consultá para que llevemos la obra a tu escuela'
                    titleFuncion={obra.title}
                    className={`ReservarFuncionViajera ${sharedStyles.glow}`}
                    id={`Reservar_BTN_${obra.title}`}
                  />
                </Row>
              </Col>
              <ul>
                <ProximasFunciones obra={obra.ID} funciones={obrasDataMerged} />
              </ul>
            </Col>
            <Col md={6}>
              {!isXs && (
                <Col style={{ position: "relative" }}>
                  <Row className={`${styles.portadaCTAContainer}`}>
                    <Col md={6}>
                      <WhatsAppButtonReserva
                        text='Reservar función'
                        titleFuncion={obra.title}
                        className='ReservarFuncionViajera'
                        id={`Reservar_BTN_${obra.title}`}
                      />
                    </Col>
                    <Col md={6} className='calculadorCTA'>
                      <Col className={`${styles.calculadorCTA}`}>
                        <Link to='/calculador' className='calculadorCTA'>
                          <LottieConatiner
                            lottieJason={calculadorCta}
                            viewerHeigth='100%'
                            classSendet='calculadorCTA'
                          />
                        </Link>
                      </Col>
                    </Col>
                  </Row>
                  <Image src={obra.imgPortada} fluid rounded />
                </Col>
              )}
              <Row className='mb-5'>
                <PhotoGalery obras={obra.images} />
              </Row>
              {videosDeLaObra(obra).length > 0 && (
                <Col>
                  {videosDeLaObra(obra).map((video, id) => (
                    <Col key={id} className={`mb-5`}>
                      <VideoLoader videoId={video.youtubeID} />
                    </Col>
                  ))}
                </Col>
              )}
              {otrasObrasDelTema(obra).length > 0 && (
                <Row md={12} className={`mt-5`}>
                  <h4>Obras relacionadas</h4>
                </Row>
              )}
              <Row xs={2} md={2} lg={2} className='g-4 mb-5 mt-1'>
                {otrasObrasDelTema(obra).map((data, id) => (
                  <Col key={id}>
                    <ObraCard
                      key={id}
                      obra={data}
                      handleClick={handleClick}
                      id={id}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
      <ScrollPercentage maxScroll={20} />
    </>
  );
};
