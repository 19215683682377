import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

export default function WhatsAppButtonReserva({
  sala = "en la escuela",
  fechaFuncion = "fecha a definir",
  titleFuncion,
  text = "Button",
  className,
  id,
}) {
  const handleClick = (consulta, title) => {
    function reemplazarEspaciosConPorcentaje20(cadena) {
      return cadena.replace(/ /g, "%20");
    }
    const titleWhatsApp = reemplazarEspaciosConPorcentaje20(title);
    window.open(
      `https://wa.me/${process.env.REACT_APP_WHATEL}/?text=Quiero%20reservar%20función%20viajera%20de%20"${titleWhatsApp}"`,
      "_blank"
    );
  };

  return (
    <Button
      variant='primary'
      onClick={() => handleClick(fechaFuncion, titleFuncion)}
      className={className}
      id={id}>
      <FontAwesomeIcon icon={faWhatsapp} size='2xl' />
      <br />
      <span>
        {text}
      </span>
    </Button>
  );
}
